<template>
  <div class="suggest">
    <div class="head"></div>
    <div class="banner" @click="onSuggestions"></div>
    <ul class="list" v-for="item in commentsList" :key="item.id" @click="onDetail(item)">
      <li class="item">
        <div class="title">{{ item.title }}</div>
        <div class="desc" v-html="item.content"></div>
        <div class="next">
          <div class="lt">发布于 {{ item.createTime }}</div>
          <div class="rt">{{ item.targetTitle }}</div>
        </div>
        <el-image
          v-if="item.isGoldenIdea"
          class="pos__icon"
          :src="require('images/index/jdz.png')"
        ></el-image>
      </li>
    </ul>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="currentSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getZKPage } from "@/api/essay";
import { mapState } from "vuex";
export default {
  name: "suggest",
    computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      currentPage: 1,
      currentSize: 10,
      total: 0,
      commentsList: [],
    };
  },
  activated(){
    console.log('activated')
  },
  deactivated(){},
  mounted() {
    this.getCommentsList();
  },
  methods: {
    onSuggestions(){
      this.$router.push("/web/pubWarehouse")
      return
      window.open('https://tongqu.qz828.com/mobile/#/pages/warehouse/pubWarehouse/pubWarehouse?refresh_token=' + this.userInfo.refresh_token)
    },
    onDetail(item){
      this.$router.push("/web/zkdetail/" + item.id);
    },
    async getCommentsList() {
      const res = await getZKPage(this.currentPage, this.currentSize);
      // console.log(res)
      this.commentsList = res.data.records;
      this.total = res.data.total;
    },
    handleSizeChange(val) {
      this.currentSize = val;
      this.getCommentsList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCommentsList();
    },
  },
};
</script>

<style lang="scss" scoped>
.suggest {
  background: #ffffff;
  .head {
    height: 69px;
    background: url("~images/suggest/head-bg.png") left top no-repeat;
    background-size: 810px 69px;
  }
  .banner {
    height: 120px;
    background: url("~images/suggest/banner2.png") center center no-repeat;
    background-size: 762px 120px;
  }
  .list {
    display: flex;
    flex-direction: column;
    padding: 20px;
    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 14px;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 18px;
      }
      .title {
        font-size: 18px;
        font-weight: 700;
        color: #333333;
      }
      .desc {
        font-size: 14px;
        color: #666666;
        line-height: 26px;
        margin-top: 14px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .next {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 26px;
        .lt {
          font-size: 13px;
          color: #666666;
        }
        .rt {
          font-size: 13px;
          color: #666666;
          padding: 5px;
          background: #e8eaed;
          margin-left: 10px;
        }
      }
      .pos__icon {
        position: absolute;
        width: 62px;
        right: 0;
        top: 40px;
      }
    }
  }
  .el-pagination {
    padding: 20px;
  }
}
</style>